<!--
 * @Author: your name
 * @Date: 2021-09-13 15:13:31
 * @LastEditTime: 2021-09-15 13:34:25
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \wxd_officials\src\views\my\repair.vue
-->
<template>
  <div style="font-family: pingfang">
    <van-form @submit="onSubmit">
      <van-field
        v-model="contact"
        name="contact"
        label="联系人"
        placeholder="联系人"
        :rules="[{ required: true, message: '请填写联系人' }]"
      />
      <van-field
        v-model="phone"
        name="phone"
        label="联系电话"
        placeholder="联系电话"
        :rules="[{ required: true, message: '请填写联系电话' }]"
      />
      <van-field
        v-model="content"
        name="content"
        rows="2"
        autosize
        label="问题内容"
        type="textarea"
        maxlength="50"
        placeholder="问题内容"
        show-word-limit
        :rules="[{ required: true, message: '请输入问题内容' }]"
      />
      <van-uploader v-model="fileList" multiple style="margin: 16px" />
      <div style="margin: 0px 16px 0px 16px">
        <van-button size="large" color="#2860b3" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { insertRepair } from "@/api/card";
export default {
  data() {
    return {
      contact: "",
      phone: "",
      content: "",
      fileList: [],
    };
  },
  methods: {
    onSubmit(values) {
      insertRepair(values, this.fileList)
        .then((res) => {
          console.log(res);
          this.contact = "";
          this.phone = "";
          this.content = "";
          this.fileList = [];
        })
        .catch((error) => {
          console.info(error);
        });
    },
  },
};
</script>

<style>
</style>
